import { focusManager } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useCurrentUserContext } from '@/contexts';

export const useInvalidateProfileOnFocus = () => {
  const { invalidate: invalidateProfile } = useCurrentUserContext();

  useEffect(() => {
    const unsubscribe = focusManager.subscribe((isVisible) => {
      if (isVisible) {
        invalidateProfile();
      }
    });

    return unsubscribe;
  }, [invalidateProfile]);
};
