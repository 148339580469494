import { useEffect, useState } from 'react';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { isPromptValid } from '@/pages/QuickCreate/utils/prompt';

type Flow = 'post-message' | 'default';

/**
 * The extension supports two different entry paths or flows:
 *
 * 1. `post-message`: when the extension is initiated through a postMessage
 *     call because the user clicked on a `Add to calendar` call-to-action.
 * 2. `default`: when the extension is initiated by the user clicking on the
 *     toolbar icon or opening the side panel directly, without a prompt.
 *
 * In addition, the default value of `flow` is `null` when the app first mounts
 * because we aren't sure what flow we're in yet. If the app receives a prompt
 * via `postMessage` immediately after it informs the extension that it's ready
 * to receive commands then we're in the `post-message` flow. If a prompt
 * doesn't come in within 500ms of the app mounting then we fall back to the
 * `default` flow.
 */
export const useFlow = () => {
  const [flow, setFlow] = useState<Flow | null>(null);
  const { prompt, promptSource } = useQuickCreateContext();

  // Switch flows if a prompt comes in via postMessage
  useEffect(() => {
    if (promptSource === 'post-message') {
      setFlow('post-message');
    }
  }, [promptSource]);

  // If we don't receive a prompt command from the extension within 500ms of
  // the app declaring itself ready, render the default flow. 500ms is an
  // arbitrary delay but in testing appears to be at least 10x longer than
  // is necessary.
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!isPromptValid(prompt)) {
        setFlow('default');
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [prompt]);

  return { flow, setFlow };
};
