import { Button, ButtonProps, Text } from '@chakra-ui/react';

const defaultShadow = '0px 0px 8px 2px rgba(0, 0, 0, 0.10)';
const activeShadow = '0px 0px 8px 2px rgba(0, 0, 0, 0.20)';

type Props = Omit<ButtonProps, 'children'>;

export const ScreenshotButton = (props: Props) => {
  return (
    <Button
      _active={{ shadow: activeShadow, transform: 'scale(0.99)' }}
      _focus={{ shadow: defaultShadow }}
      _hover={{ shadow: activeShadow }}
      bg="white"
      borderRadius="xl"
      fontWeight="bold"
      lineHeight="1"
      shadow={defaultShadow}
      variant="secondary"
      w="100%"
      {...props}
    >
      <Text as="span" mr="2">
        📸
      </Text>
      Take Screenshot
    </Button>
  );
};
