import { useEffect } from 'react';
import { Sentry } from '@/lib';
import { usePostMessageReceiver } from './usePostMessageReceiver';

/**
 * This hook fixes an issue where the side panel's devicePixelRatio is
 * sometimes different than the host page, resulting in the panel appearing
 * zoomed in even though the font sizes are the same. I think this is a bug
 * in Chrome's side panel implementation.
 *
 * In this fix, we first send a message to the extension's side panel asking
 * for the device pixel ratio of the host page. The side panel forwards the
 * request to the background script which captures the value, then threads the
 * value back to this hook (inside the iframe) through the side panel.
 */

export const useZoomFix = () => {
  useEffect(() => {
    // Ask the extension to send us the device pixel ratio of the host page
    window.parent.postMessage('_ah_pixel_ratio_request', '*');
  }, []);

  usePostMessageReceiver('_ah_pixel_ratio_response', (message) => {
    const hostDevicePixelRatio = parseFloat(message);

    if (window.devicePixelRatio > hostDevicePixelRatio) {
      // @ts-expect-error zoom is a non-standard property
      document.documentElement.style.zoom =
        hostDevicePixelRatio / window.devicePixelRatio;

      Sentry.captureMessage('Zoom fix applied', {
        tags: {
          extension: true,
        },
        extra: {
          hostDevicePixelRatio,
          windowDevicePixelRatio: window.devicePixelRatio,
        },
      });
    }
  });
};
