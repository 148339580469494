import { Collapse } from '@/components/Collapse';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';

export const PromptError = () => {
  const { createMutation } = useQuickCreateContext();

  return (
    <Collapse in={createMutation.isError}>
      {createMutation.error && (
        <QuickCreateErrorMessage error={createMutation.error} />
      )}
    </Collapse>
  );
};
