import { CenteredContent } from '@/pages/QuickCreate/components/CenteredContent';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { EntryList } from '../../components/EntryList';
import { LoadingCard } from '../../components/LoadingCard';
import { ResultsFeedbackForm } from '../../components/ResultsFeedbackForm';
import { PromptError } from '../components/PromptError';
import { ResetButton } from '../components/ResetButton';

type Props = {
  onCreateAnother: () => void;
};

export const PostMessageFlow = ({ onCreateAnother }: Props) => {
  const { state } = useQuickCreateContext();

  return (
    <CenteredContent gap="6" pt="1">
      <PromptError />

      {state === 'loading' ? (
        <LoadingCard />
      ) : state === 'edit' ? (
        <>
          <EntryList />
          <ResultsFeedbackForm />
          <ResetButton onClick={onCreateAnother} />
        </>
      ) : null}
    </CenteredContent>
  );
};
